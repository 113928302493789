import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuIcon from '@material-ui/icons/Menu';
import MenuClose from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import RAnchorLink from 'react-anchor-link-smooth-scroll';

const AnchorLink = props => (
  // Ensure to always have offset to avoid sticky header.
  // eslint-disable-next-line react/jsx-props-no-spreading
  <RAnchorLink {...props} offset="120" />
);

const menuPropTypes = {
  isOpen: PropTypes.bool.isRequired,
  anchorLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const Menu = ({ isOpen, anchorLinks }) =>
  isOpen ? (
    <Container
      style={{
        width: '50%',
        position: 'absolute',
        right: '0',
        top: '120px',
        padding: '0',
      }}
    >
      <Typography
        component="div"
        style={{
          backgroundColor: 'white',
          display: 'flex',
          flexDirection: 'column',
          height: '100vh',
          border: '1px solid #d8d8d8',
        }}
      >
        {anchorLinks.map(anchor => (
          <AnchorLink key={anchor} href={`#${anchor.toLowerCase()}`}>
            {anchor}
          </AnchorLink>
        ))}
      </Typography>
    </Container>
  ) : null;

Menu.propTypes = menuPropTypes;

const propTypes = {
  anchorLinks: PropTypes.arrayOf(PropTypes.string).isRequired,
  isSmall: PropTypes.bool.isRequired,
  isVerySmall: PropTypes.bool.isRequired,
};

const Header = ({ anchorLinks, isSmall, isVerySmall }) => {
  const [open, setOpen] = useState(false);

  return (
    <div className="header">
      <div className="header-content">
        <h1>Trafimage Developer Portal</h1>
        {isSmall ? (
          <div
            className={`small-menu-toggler ${
              isVerySmall ? ' xs-menu-toggler' : ''
            }`}
          >
            <img
              className={`logo ${isVerySmall ? ' xs-logo' : ''}`}
              src="/img/sbb_logo.png"
              alt="SBB Logo"
            />
            <div className="menu-icon-wrapper">
              <IconButton
                onClick={() => setOpen(!open)}
                style={{ padding: '15px 0 12px 0' }}
              >
                {!open ? (
                  <MenuIcon focusable={false} />
                ) : (
                  <MenuClose focusable={false} />
                )}
              </IconButton>
            </div>
            <Menu isOpen={open} anchorLinks={anchorLinks} />
          </div>
        ) : (
          <>
            <div className="menu-toggler">
              {anchorLinks.map(anchor => (
                <AnchorLink key={anchor} href={`#${anchor.toLowerCase()}`}>
                  {anchor}
                </AnchorLink>
              ))}
            </div>
            <img className="logo" src="/img/sbb_logo.png" alt="SBB Logo" />
          </>
        )}
      </div>
    </div>
  );
};

Header.propTypes = propTypes;

export default Header;
