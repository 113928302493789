import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import LinkIcon from '@material-ui/icons/Link';
import GitHubIcon from '@material-ui/icons/GitHub';
import CodeIcon from '@material-ui/icons/Code';
import MailIcon from '@material-ui/icons/Mail';

const propTypes = {
  title: PropTypes.string.isRequired,
  subTitle: PropTypes.string.isRequired,
  imageUrl: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
  links: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  fullWidth: PropTypes.bool,
};

const defaultProps = {
  fullWidth: false,
};

const useStyles = makeStyles(theme => ({
  card: {
    width: '100%',
    justifyContent: 'space-between',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    height: 5,
    paddingTop: '56.25%', // 16:9
  },
  fullWidthMedia: {
    paddingTop: '15%',
  },
  expand: {
    transform: 'rotate(0deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  avatar: {
    backgroundColor: '#003d85',
  },
}));

function DocCard({ title, subTitle, imageUrl, content, links, fullWidth }) {
  const classes = useStyles();
  const avatar = title.substr(0, 1);

  const getIcon = name =>
    ({
      link: <LinkIcon />,
      mail: <MailIcon />,
      github: <GitHubIcon />,
      npm: <CodeIcon />,
    }[name] || null);

  return (
    <Card className={classes.card}>
      <CardHeader
        avatar={<Avatar className={classes.avatar}>{avatar}</Avatar>}
        title={title}
        subheader={subTitle}
      />

      <CardMedia
        className={fullWidth ? classes.fullWidthMedia : classes.media}
        image={imageUrl}
        title={title}
      />

      <CardContent>
        <Typography variant="body2" color="textSecondary" component="p">
          {content}
        </Typography>
      </CardContent>

      <CardActions disableSpacing>
        {links.map(link => (
          <div key={link.href}>
            <IconButton
              href={link.href}
              title={link.title}
              target={link.href.startsWith('mailto') ? '_self' : '_blank'}
            >
              {getIcon(link.icon)}
            </IconButton>
            {link.displayText || null}
          </div>
        ))}
      </CardActions>
    </Card>
  );
}

DocCard.propTypes = propTypes;
DocCard.defaultProps = defaultProps;

export default DocCard;
