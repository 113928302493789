import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuClose from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MUIModal from '@material-ui/core/Modal';

function getModalStyle() {
  return {
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  };
}

const CloserIcon = withStyles({
  root: {
    position: 'absolute',
    top: 20,
    right: 20,
    height: 20,
    width: 20,
    padding: 0,
    '& svg': {
      fontSize: 20,
    },
  },
})(({ close, classes }) => (
  <IconButton classes={classes} title="Close Modal" onClick={close}>
    <MenuClose focusable={false} />
  </IconButton>
));

const useStyles = makeStyles(theme => ({
  paper: {
    position: 'absolute',
    width: '80%',
    maxWidth: 400,
    backgroundColor: theme.palette.background.paper,
    border: '1px solid #444',
    boxShadow: theme.shadows[5],
    padding: '20px',
  },
}));

const propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
};

const defaultProps = {
  children: null,
};

function Modal({ open, onClose, title, children }) {
  const classes = useStyles();
  // getModalStyle is not a pure function, we roll the style only on the first render
  const [modalStyle] = useState(getModalStyle);

  return (
    <MUIModal
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      open={open}
      onClose={() => onClose()}
    >
      <div style={modalStyle} className={classes.paper}>
        <div className="modal-title">{title}</div>
        <div className="modal-description">
          <Typography variant="body1" color="textPrimary">
            {children}
          </Typography>
        </div>
        <CloserIcon close={() => onClose()} />
      </div>
    </MUIModal>
  );
}

Modal.propTypes = propTypes;
Modal.defaultProps = defaultProps;

export default Modal;
