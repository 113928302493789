import React from 'react';
/* eslint-disable max-len */
const config = [
  {
    title: 'Applications',
    description: (
      <span>
        Documentation of the Trafimage Web applications.
        <br />
        Please write an email to{' '}
        <a href="mailto:trafimage@sbb.ch">trafimage@sbb.ch</a> if you use want
        to use the application on your Web page.
      </span>
    ),
    cards: [
      {
        title: 'Interactive Station Plans',
        subTitle: 'All stations at a glance.',
        imageUrl: '/img/plans.jpg',
        description: `
          Information about integrating an interactive station plan into an
          existing Web page.`,
        links: [
          {
            title: 'API Documentation',
            icon: 'link',
            href: '//plans.trafimage.ch/doc',
          },
          {
            title: 'NPM package',
            icon: 'npm',
            href: '//www.npmjs.com/package/@geops/interactive-stationplans',
          },
        ],
      },
      {
        title: 'Trafimage Maps',
        subTitle: 'Create your own map.',
        imageUrl: '/img/wkp.png',
        description: `
          Integrate an SBB map into your web page.
        `,
        links: [
          {
            title: 'API Documentation',
            icon: 'link',
            href: '//jsdoc.maps.trafimage.ch',
          },
          {
            title: 'Project on GitHub',
            icon: 'github',
            href: '//github.com/geops/trafimage-maps',
          },
          {
            title: 'NPM package',
            icon: 'npm',
            href: '//www.npmjs.com/package/trafimage-maps',
          },
        ],
      },
    ],
  },
  {
    title: 'Maps',
    description: (
      <>
        <p>
          Trafimage maps are used to illustrate a whole range of topics relating
          to public transport. We provide maps using TileServer GL which enables
          us to deliver Vector- and Raster tiles. To get more information how to
          integrate one of our maps into your existing Web page, send send an
          email to <a href="mailto:trafimage@sbb.ch">trafimage@sbb.ch</a>.
        </p>
        <p>
          For the private use of maps you can use the{' '}
          <a href="https://developer.geops.io/" target="geops">
            <b>geOps Developer services</b>
          </a>
          .
        </p>
      </>
    ),
    cards: [
      {
        title: 'Trafimage Network Map',
        subTitle: 'Swiss public transport map',
        imageUrl: '/img/map.png',
        description: `
          Trafimage maps cover all aspects of public transportation in
          Switzerland and neighbouring countries. Contact us if you want to
          integrate one of our maps into your web page. It's also possible to
          get a customized version of our maps that fulfill your requirements.
        `,
        width: 12,
        links: [
          {
            title: 'Documentation',
            icon: 'link',
            href:
              '//confluence.sbb.ch/display/TRAFIEXTERN/Tileserver+GL+Karten',
          },
          {
            title: 'Mail',
            icon: 'mail',
            href: 'mailto:trafimage@sbb.ch',
          },
        ],
      },
    ],
  },
  {
    title: 'REST-APIs',
    description: (
      <>
        <p>
          A lot of the data we use in our applications is open data. This
          includes information about services at the biggest stations and public
          transport. You can use our REST APIs for accessing this data.
        </p>
        <p>
          For the private use of maps you can use the{' '}
          <a href="https://developer.geops.io/" target="geops">
            <b>geOps Developer services</b>
          </a>
          .
        </p>
      </>
    ),
    cards: [
      {
        title: 'Station Plans Data',
        subTitle: 'All stations at a glance.',
        imageUrl: '/img/api.png',
        description: `
          Most of the interactive station plan data is open and can be used
          freely. This includes data about services at a station and platforms.
        `,
        links: [
          {
            title: 'API Documentation',
            icon: 'link',
            href: '//api.insa.geops.ch/docs/',
          },
        ],
      },
    ],
  },
  {
    title: 'Terms of use',
    description: (
      <>
        <p>
          Certain conditions apply to the use of Trafimage data and products.
          These can be found in the following document (available in German and
          French). By using Trafimage data or products you agree to the terms of
          use. If you have any questions regarding the conditions, please
          contact <a href="mailto:trafimage@sbb.ch">trafimage@sbb.ch</a>.
        </p>
        <ul>
          <li>
            <a
              href="Bestimmung_Datenabgabe_v1_2024_DE.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of use (German)
            </a>
          </li>
          <li>
            <a
              href="Bestimmung_Datenabgabe_v1_2024_FR.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Terms of use (French)
            </a>
          </li>
        </ul>
      </>
    ),
    cards: [],
  },
  {
    title: 'More',
    description: (
      <>
        <span>
          Collection of various SBB internal documentations about Trafimage, the
          system and its products.
        </span>
        <br />
        <span>
          External User: If you have any questions, please contact us:
        </span>{' '}
        <a href="mailto:trafimage@sbb.ch">trafimage@sbb.ch</a>
      </>
    ),
    cards: [
      {
        title: 'System Trafimage',
        subTitle: 'General Trafimage documentation.',
        imageUrl: '/img/trafimage.jpg',
        description: `
          Description of the IT- / graphics system, internal processes, system
          administration, etc. German only.
        `,
        links: [
          {
            title: 'Link',
            icon: 'link',
            href:
              'https://confluence.sbb.ch/display/TRAFIEXTERN/Trafimage+Dokumentation',
          },
        ],
      },
      {
        title: 'SBB Confluence',
        subTitle: 'General Trafimage documentation.',
        imageUrl: '/img/wkp_laptop.jpg',
        description: `
          Information about contacts, organization, user manuals, disruption
          manual, requirement management etc. German only.
        `,
        links: [
          {
            title: 'Link',
            icon: 'link',
            href:
              'https://confluence.sbb.ch/display/TRAFIMAGE/Trafimage+-+Fachbus',
          },
        ],
      },
      {
        title: 'EA Model',
        subTitle: 'EA model of plans- and maps application.',
        imageUrl: '/img/EA-Modell.png',
        description: `
          Access the EA model of the plans and maps application.
        `,
        links: [
          {
            title: 'Link',
            icon: 'link',
            displayText: 'Plans',
            href:
              'https://sbb.ardoq.com/discover/details/32baebbe46458e7ae906542f',
          },
          {
            title: 'Link',
            icon: 'link',
            displayText: 'Maps',
            href:
              'https://sbb.ardoq.com/discover/details/ec57af2d7aa897b70864e2dc',
          },
        ],
      },
    ],
  },
];

export default config;
