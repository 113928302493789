import React from 'react';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import {
  makeStyles,
  createMuiTheme,
  ThemeProvider,
} from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';

import Card from './components/Card';
import Header from './components/Header';
import Footer from './components/Footer';

import contentConfig from './config/content';
import './App.css';

const useStyles = makeStyles(() => ({
  root: { flexGrow: 1 },
}));

const theme = createMuiTheme({
  spacing: 8,
  palette: {
    text: {
      primary: '#444',
    },
  },
  typography: {
    fontFamily: 'Arial',
    color: '#444',
    h1: {
      fontSize: '2rem',
    },
  },

  overrides: {
    MuiTypography: {
      gutterBottom: {
        marginBottom: 16, // 16px
      },
    },
  },
});

function App() {
  const classes = useStyles();
  const anchorLinks = contentConfig.map(c => c.title);

  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
  const isVerySmall = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Header
          anchorLinks={anchorLinks}
          isSmall={isSmall}
          isVerySmall={isVerySmall}
        />
        <Container
          maxWidth="lg"
          style={{
            marginTop: 160,
            padding: 0,
          }}
        >
          {contentConfig.map(content => (
            <Box m={5} key={content.title}>
              <Typography variant="h1" color="textPrimary" gutterBottom>
                <div id={content.title.toLowerCase()}>{content.title}</div>
              </Typography>
              <Typography variant="body1" color="textPrimary" gutterBottom>
                {content.description}
              </Typography>
              <Grid
                container
                xs={12}
                spacing={isVerySmall ? 0 : 3}
                className={classes.root}
              >
                {content.cards.map(card => (
                  <Grid
                    item
                    xs={12}
                    sm={card.width || 4}
                    style={{ marginBottom: isVerySmall ? '20px' : 0 }}
                    key={card.title}
                  >
                    <Card
                      title={card.title}
                      subTitle={card.subTitle}
                      imageUrl={card.imageUrl}
                      content={card.description}
                      links={card.links}
                      fullWidth={card.width === 12}
                    />
                  </Grid>
                ))}
              </Grid>
            </Box>
          ))}
        </Container>
        <Footer />
      </ThemeProvider>
    </div>
  );
}

export default App;
