import React from 'react';
import Link from '@material-ui/core/Link';
import Modal from './Modal';

const titles = {
  imprint: 'Imprint',
  legal: 'Legal Information',
};

const descriptions = {
  imprint: (
    <>
      <div className="modal-description-item">
        <b>SBB Product Owner</b>
        <br />
        <span>
          {`
            Infrastructure – Installations and Technology –
            Technology Operation Center – Technical Management
          `}
        </span>
        <br />
        <span>Fachbus Trafimage</span>
        <br />
        <span>Poststrasse 6</span>
        <br />
        <span>3072 Ostermundingen</span>
        <br />
        <span>Switzerland</span>
        <br />
        <a href="mailto:trafimage@sbb.ch">trafimage@sbb.ch</a>
        <br />
        <a href="http://www.trafimage.ch">www.trafimage.ch</a>
      </div>
      <div>
        <b>geOps AG</b>
        <br />
        <span>Solothurnerstrasse 235</span>
        <br />
        <span>CH-4600 Olten</span>
        <br />
        <a href="mailto:info@geops.ch">info@geops.ch</a>
        <br />
        <a href="http://www.geops.ch">www.geops.ch/</a>
      </div>
    </>
  ),
  legal: (
    <>
      <div>SBB Product Owner</div>
      <div>geOps</div>
    </>
  ),
};

/* eslint-disable jsx-a11y/anchor-is-valid */
const Footer = () => {
  const [open, setOpen] = React.useState(false);

  return (
    <div className="footer">
      <div className="footer-content">
        <Link onClick={() => setOpen('imprint')}>Imprint</Link>
        {/*
          <Link onClick={() => setOpen('legal')}>
            Legal Information
          </Link>
        */}
      </div>
      <Modal
        open={!!open}
        onClose={() => setOpen(false)}
        title={open ? titles[open] : ''}
      >
        {open ? descriptions[open] : null}
      </Modal>
    </div>
  );
};

export default Footer;
